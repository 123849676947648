/**
 * The store banner which enables the customer to see her preferred store. Requires the `Observer` and `TimeZone` libraries to be present on the page.
 */
export default class StoreBanner {

    /**
    * Display the preferred store details that is currently saved in local storage.
    */
    static displayPreferredStore(): void {
        console.groupCollapsed("storeBanner: displayedPreferredStore() called.");
        StoreBanner.displayStoreName();
        StoreBanner.displayStoreHours();
        console.groupEnd();
    }

    /**
     * Display the store name saved in local storage.
     */
    private static displayStoreName(): void {
        const city = localStorage.getItem("preferredStoreCity");
        const state = localStorage.getItem("preferredStoreState");
        const id = localStorage.getItem("preferredStoreId");

        /* Note: Want falsy values in case some values are undefined. */
        if (city == null || state == null || id == null) {
            console.log("StoreBanner.displayStoreName: Store name not found.");
        } else {
            console.log("StoreBanner.displayStoreName: Store name found.");
            const selectStore = document.querySelector("#ys-select-store") as HTMLDivElement;
            selectStore.style.display = "none";
            const yourStoreLabel = document.querySelector("#ys-store-label") as HTMLDivElement;
            yourStoreLabel.style.display = "inline";
            const storeId = document.querySelector("#ys-store-id") as HTMLDivElement;
            storeId.innerHTML = city + ", " + state + " #" + id;
        }
    }

    /**
     * Display the store hours saved in local storage.
     */
    static displayStoreHours(): void {
        const hoursStr = localStorage.getItem("preferredStoreHours");
        if (hoursStr === null) {
            console.log("storeBanner.displayStoreHours: Store hours not found.");
        } else {
            console.log("storeBanner.displayStoreHours: Store hours found.");

            const openTillText = window.TimeZone.getOpenTillTextFromStoreHours(hoursStr);

            const preferredStoreHoursDiv = document.querySelector("#ys-open-hours") as HTMLDivElement;
            preferredStoreHoursDiv.innerHTML = openTillText;

            const storeBanner = document.querySelector("#your-store-banner") as HTMLElement;
            const dot = storeBanner.querySelector(".dot") as HTMLDivElement;
            dot.style.display = "inline-block";

            if (openTillText === "Currently Closed") {
                dot.classList.remove("green-dot");
                dot.classList.add("red-dot");
            } else {
                dot.classList.remove("red-dot");
                dot.classList.add("green-dot");
            }
        }
    }
}
