import "../sass/index";
import "./storeBanner";
import StoreBanner from "./storeBanner";

document.addEventListener("DOMContentLoaded", (): void => {
    console.log("storeBanner: begin check for Observer");

    window.storeBanner = StoreBanner;
    StoreBanner.displayPreferredStore();

    const count = 0;
    const limit = 10;
    const delay = 100;

    const interval = setInterval((count: number, limit: number): void => {
        if (typeof window.observer !== "undefined") {
            console.log("storeBanner: Observer found");
            window.observer.subscribe("PreferredStoreChanged", StoreBanner.displayPreferredStore);
            clearInterval(interval);
        } else if (count >= limit) {
            clearInterval(interval);
        } else {
            count++;
        }
    }, delay, count, limit);

});
